import React from 'react'

import { Grid, Paper, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  email: {
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    color: theme.palette.text.primary,
  },
}))

export default function Introduction() {
  const classes = useStyles()
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant='body1' className={classes.email}>
            OUR MISSION
          </Typography>
          <Typography variant='body2'>
            Empowering people to save energy they do not know they were
            consuming.
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant='body1' className={classes.email}>
            OUR VALUES
          </Typography>
          <div>
            <Typography variant='body2'>
              Transparent, Sustainable, Lean
            </Typography>
            <ul style={{ textAlign: 'left' }}>
              <li>
                <Typography variant='body2'>
                  <b>Transparent:</b> Full transparency on pleco cost structure,
                  we do not store any data related to the emails, only stats to
                  praise savings (eg: number of email processed).
                </Typography>
              </li>
              <li>
                <Typography variant='body2'>
                  <b>Sustainable:</b> We select our partner for their
                  sustainability commitment, even if we have to use google, we
                  rely on their 0 emission{' '}
                  <a
                    href='https://cloud.google.com/sustainability'
                    rel='noreferrer'
                    target='_blank'
                  >
                    recent commitment
                  </a>
                  .
                </Typography>
              </li>
              <li>
                <Typography variant='body2'>
                  <b>Lean:</b> We rely on cloud function to use only resources
                  when needed, we only store minimal data, we rely on existing
                  solution already in place in your gmail client.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant='body1' className={classes.email}>
            HOW IT WORKS
          </Typography>
          <Typography variant='body2'>
            Technically we reuse the filter system already in place in Gmail
            API. We make it much more easier to use.
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  )
}
