import React, { Component } from 'react'

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core'

import ExitToAppIcon from '@material-ui/icons/ExitToApp'

class NavBar extends Component {
  render() {
    return (
      <AppBar position='sticky'>
        <Toolbar>
          <IconButton
            edge='start'
            style={{ marginRight: '12px' }}
            color='inherit'
            aria-label='menu'
          >
            <img
              alt='logo'
              width={30}
              style={{ marginRight: '12px' }}
              src={process.env.PUBLIC_URL + `logo.png`}
            />
            <div>Pleco.io</div>
          </IconButton>
          <Typography variant='caption' style={{ flexGrow: 1 }}></Typography>
          {this.props.userName === '' ? (
            <Button color='inherit' onClick={this.props.handleLogin}>
              Login
            </Button>
          ) : (
            <>
              <Button color='inherit'>{this.props.userName}</Button>
              <Button color='inherit' onClick={this.props.handleSignOut}>
                <ExitToAppIcon />
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
    )
  }
}
export default NavBar
