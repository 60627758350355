import React, { Component } from 'react'
import axios from 'axios'

import Sender from '../components/Sender'
import FormDialog from '../components/FormDialog'

import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { BASE_URL } from '../config'
import Introduction from '../components/Introduction'
import NewFilterForm from '../components/NewFilterForm'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  email: {
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    height: '30px',
    color: theme.palette.text.secondary,
  },
}))

function Header(props) {
  const classes = useStyles()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div className={classes.paper}>
          <Typography variant='body1' className={classes.email}>
            {props.message}
          </Typography>
        </div>
      </Grid>
    </Grid>
  )
}

const SENDERS = [
  {
    name: 'Github',
    email: 'noreply@github.com',
    filterCreated: false,
  },
  {
    name: 'Meet',
    email: 'noreply@meet.google.com',
    filterCreated: false,
  },
  {
    name: 'Medium',
    email: 'noreply@medium.com',
    filterCreated: false,
  },
  {
    name: 'Calendly',
    email: 'noreply@calendly.com',
    filterCreated: false,
  },
]

/**
 *
 * CLASS DASHBOARD
 * DISPLAY EMAIL PROVIDERS
 *
 */

class Dashboard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: props.token,
      isSignedIn: props.token !== '',
      loading: false,
      shouldOpenDialog: false,
      currentEmail: '',
      errorMessage: '',
      readEmailsDuration: 1,
      unreadEmailsDuration: 1,
      trashDuration: 30,
      shouldApplyExisting: 0,
      senders: SENDERS,
      customEmail: '',
      nbMessagesConcerned: 0,
    }
    this.baseState = {
      loading: false,
      shouldOpenDialog: false,
      currentEmail: '',
      errorMessage: '',
      readEmailsDuration: 1,
      unreadEmailsDuration: 1,
      trashDuration: 30,
      shouldApplyExisting: 0,
      senders: SENDERS,
      customEmail: '',
      nbMessagesConcerned: 0,
    }
  }

  componentDidUpdate(prevProps) {
    const { token } = this.props
    if (token !== prevProps.token) {
      if (token !== '') {
        this.setState({ isSignedIn: true, token })
      } else {
        this.setState({ isSignedIn: false })
      }
    }
  }

  handleClickOpen = email => {
    if (!this.state.isSignedIn) alert('You must first Login.')
    else {
      this.setState({ shouldOpenDialog: true, currentEmail: email })
      this.findMatchingMessages(email, false)
    }
  }

  handleAddEmail = () => {
    const { customEmail } = this.state

    if (customEmail !== '' && customEmail.includes('@')) {
      this.handleClickOpen(customEmail)
    }
  }

  //TODO : Able to receive various parameters and treat them
  findMatchingMessages = async (email = '', isReload = true) => {
    const {
      token,
      currentEmail,
      readEmailsDuration,
      unreadEmailsDuration,
      trashDuration,
    } = this.state

    const url = `${BASE_URL}/findMatchingMessages?token=${token}&email=${
      isReload ? currentEmail : email
    }&trashDuration=${trashDuration}&readEmailsDuration=${readEmailsDuration}&unreadEmailsDuration=${unreadEmailsDuration}`
    const resultFinMatchingMessages = await axios.get(url)

    if (
      resultFinMatchingMessages.data &&
      resultFinMatchingMessages.data.success
    ) {
      this.setState({
        nbMessagesConcerned: resultFinMatchingMessages.data.nbMessages,
      })
    } else {
      console.log('ERROR FIND MATCHING MESSAGES', resultFinMatchingMessages)
    }
  }

  handleChangeEmail = ev => {
    const { name, value } = ev.target
    this.setState({ [name]: value })
  }

  handleClose = () => {
    this.setState(this.baseState)
  }

  handleChangeDuration = ev => {
    const { name, value } = ev.target

    this.setState({ [name]: value })
  }

  handleCreateFilters = async () => {
    const {
      token,
      currentEmail,
      readEmailsDuration,
      unreadEmailsDuration,
      shouldApplyExisting,
      trashDuration,
      senders,
    } = this.state

    this.setState({ loading: true, errorMessage: '' })

    const url = `${BASE_URL}/createFilter?token=${token}&email=${currentEmail}&trashDuration=${trashDuration}&readEmailsDuration=${readEmailsDuration}&unreadEmailsDuration=${unreadEmailsDuration}`
    const resultCreateFilters = await axios.get(url)

    //Result handling CREATION FILTERS SUCCESS
    if (resultCreateFilters.data && resultCreateFilters.data.success) {
      const sendersEmails = SENDERS.filter(
        sender => sender.email === currentEmail && currentEmail
      )
      if (sendersEmails.length === 0) {
        const newSender = {
          name: 'pleco',
          email: currentEmail,
          filterCreated: true,
        }
        senders.push(newSender)
        this.setState({ senders })
      } else {
        this.handleUpdateSenders(currentEmail)
      }

      if (shouldApplyExisting) {
        const url = `${BASE_URL}/applyMatchingMessages?token=${token}&email=${currentEmail}&trashDuration=${trashDuration}&readEmailsDuration=${readEmailsDuration}&unreadEmailsDuration=${unreadEmailsDuration}`
        const resultApplyExisting = await axios.get(url)

        //Result handling APPLY MATCHING SUCCESS
        if (resultApplyExisting.data && resultApplyExisting.data.success) {
          this.handleClose()
          //Result handling APPLY MATCHING FAILURE
        } else {
          console.log('APPLY ERROR', resultApplyExisting)
          this.setState({ loading: false, errorMessage: 'Unknown error' })
        }
      } else {
        this.handleClose()
      }
      //Result handling CREATION FILTERS FAILURE
    } else if (
      resultCreateFilters.data &&
      resultCreateFilters.data.errors[0].message
    ) {
      this.setState({
        loading: false,
        errorMessage: resultCreateFilters.data.errors[0].message,
      })
      //Result handling CREATION FILTERS UNKNOWN ERROR
    } else {
      console.log('CREATE FILTER ERROR', resultCreateFilters)
      this.setState({ loading: false, errorMessage: 'Unknown error' })
    }
  }

  handleUpdateSenders = email => {
    const tempSenders = this.state.senders

    const elementsIndex = tempSenders.findIndex(
      sender => sender.email === email
    )

    tempSenders[elementsIndex].filterCreated = true

    this.setState({ senders: tempSenders })
  }

  render() {
    const {
      shouldOpenDialog,
      isSignedIn,
      loading,
      currentEmail,
      errorMessage,
      readEmailsDuration,
      unreadEmailsDuration,
      shouldApplyExisting,
      trashDuration,
      senders,
      customEmail,
      nbMessagesConcerned,
    } = this.state

    return (
      <div>
        {isSignedIn ? (
          <>
            <Header message={'RAISE PLECO(S)'} />
            <Typography variant='body1'>
              Each pleco will help you keep your inbox clean… and save the
              planet ;-)
              <br />
              How: a pleco will move your messages from inbox to a tank and
              finally to the trash
            </Typography>
            <br />
            {senders.map(sender => (
              <div
                key={sender.email}
                onClick={() => this.handleClickOpen(sender.email)}
              >
                <Sender
                  name={sender.name}
                  email={sender.email}
                  filterCreated={sender.filterCreated}
                />
              </div>
            ))}

            <FormDialog
              email={currentEmail}
              errorMessage={errorMessage}
              loading={loading}
              open={shouldOpenDialog}
              readEmailsDuration={readEmailsDuration}
              unreadEmailsDuration={unreadEmailsDuration}
              shouldApplyExisting={shouldApplyExisting}
              nbMessagesConcerned={nbMessagesConcerned}
              trashDuration={trashDuration}
              findMatchingMessages={this.findMatchingMessages}
              handleClose={this.handleClose}
              handleChange={this.handleChangeDuration}
              handleCreateFilters={this.handleCreateFilters}
            />

            <NewFilterForm
              email={customEmail}
              handleChange={this.handleChangeEmail}
              handleAddEmail={this.handleAddEmail}
            />
          </>
        ) : (
          <>
            <Header message={'WELCOME ON PLECO!'} />
            <Introduction />
          </>
        )}
      </div>
    )
  }
}

export default Dashboard
