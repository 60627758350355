import './App.css'

import React, { Component } from 'react'
import firebase from 'firebase'
import axios from 'axios'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import Dashboard from './containers/Dashboard'
import NavBar from './components/commons/NavBar'
import { BASE_URL } from './config'

const config = {
  apiKey: 'AIzaSyAzS0qYnR7RS1d5n1zvfUOumNNuLrhJhnI',
  //authDomain: 'pleco-2ad03.firebaseapp.com',
  authDomain: 'dev.pleco.io',
  databaseURL: 'https://pleco-2ad03.firebaseio.com',
  projectId: 'pleco-2ad03',
  storageBucket: 'pleco-2ad03.appspot.com',
  messagingSenderId: '691641253919',
  appId: '1:691641253919:web:98782c987035a351b3952a',
}

let theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: '#BBDEFB',
      main: '#2196F3',
      dark: '#1976D2',
    },
    secondary: {
      main: '#FF9800',
    },
    textPrimary: {
      main: '#ffffff',
    },
  },
})

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      userName: '',
      userEmail: '',
      userToken: '',
    }
  }

  componentDidMount = () => {
    const storageUserToken = localStorage.getItem('userToken')
    const storageUserName = localStorage.getItem('userName')
    const storageUserEmail = localStorage.getItem('userEmail')

    if (storageUserEmail && storageUserName && storageUserToken) {
      this.setState({
        userEmail: storageUserEmail,
        userName: storageUserName,
        userToken: storageUserToken,
      })
    }
  }

  getExps = async token => {
    const baseUrl = `${BASE_URL}/listExpeditors?nbEmails=10&token=`
    axios
      .get(baseUrl + token)
      .then(function (response) {
        // handle success
        console.log(response)
        if (response.data) {
          console.log(response.data.expeditors)
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
  }

  handleLogin = async () => {
    console.log('WE LOGIN App.js')
    //auth.signInWithGoogle()
    if (!firebase.apps.length) {
      firebase.initializeApp(config)
    }

    var provider = new firebase.auth.GoogleAuthProvider()

    provider.addScope('https://www.googleapis.com/auth/gmail.readonly')
    provider.addScope('https://www.googleapis.com/auth/gmail.labels')
    provider.addScope('https://www.googleapis.com/auth/gmail.settings.basic')
    provider.addScope('https://www.googleapis.com/auth/gmail.settings.sharing')
    provider.addScope('https://www.googleapis.com/auth/gmail.modify')

    const result_signin = await firebase.auth().signInWithPopup(provider)

    if (result_signin.credential) {
      const token = result_signin.credential.accessToken

      const user = result_signin.user
      
      console.log(token)

      localStorage.setItem('userToken', token)
      localStorage.setItem('userEmail', user.email)
      localStorage.setItem('userName', user.displayName)

      this.setState({
        userEmail: user.email,
        userName: user.displayName,
        userToken: token,
      })
      //this.getExps(token)
    } else {
      console.log('errorMessage : \n', result_signin)
    }

  }

  handleSignOut = () => {
    this.setState({
      userEmail: '',
      userName: '',
      userToken: '',
    })
    localStorage.clear()
    firebase.auth().signOut()
    window.location.reload()
  }

  getToken = () => {
    return this.state.userToken
  }

  render() {
    const { userName, userToken } = this.state
    
    return (
      <MuiThemeProvider theme={theme}>
        <NavBar
          classes={this.props.classes}
          userName={userName}
          handleLogin={this.handleLogin}
          handleSignOut={this.handleSignOut}
        />
        <div
          className='App'
          style={{ overflow: 'hidden', width: '100%', height: '100%' }}
        >
          <Dashboard token={userToken} getToken={this.getToken} />
        </div>
      </MuiThemeProvider>
    )
  }
}

export default App
