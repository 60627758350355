import React from 'react'

import {
  FormControl,
  MenuItem,
  Select,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Typography,
} from '@material-ui/core/'

export default function FormDialog({
  email,
  errorMessage,
  open,
  loading,
  nbMessagesConcerned,
  handleClose,
  handleChange,
  handleCreateFilters,
  readEmailsDuration,
  unreadEmailsDuration,
  shouldApplyExisting,
  findMatchingMessages,
  trashDuration,
}) {
  const questions = [
    {
      name: 'readEmailsDuration',
      text: `How many days read emails should stay in inbox?`,
      value: readEmailsDuration,
    },
    {
      name: 'unreadEmailsDuration',
      text: 'How many days unread emails should stay in inbox?',
      value: unreadEmailsDuration,
    },
    {
      name: 'trashDuration',
      text: 'How long before delete - clean from tank?',
      value: trashDuration,
    },
  ]

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>{email}</DialogTitle>
        <Typography variant={'body2'} color={'error'}>
          {errorMessage}
        </Typography>
        {loading && <CircularProgress />}
        <DialogContent>
          {questions.map(question => (
            <React.Fragment key={question.name}>
              <DialogContentText>{question.text}</DialogContentText>

              <FormControl fullWidth>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  name={question.name}
                  value={question.value}
                  disabled={loading}
                  onChange={handleChange}
                >
                  <MenuItem value={1}>1 Day</MenuItem>
                  <MenuItem value={5}>5 Days</MenuItem>
                  <MenuItem value={10}>10 Days</MenuItem>
                  <MenuItem value={30}>30 Days</MenuItem>
                </Select>
              </FormControl>

              <br />
              <br />
            </React.Fragment>
          ))}
          {nbMessagesConcerned > 0 && (
            <>
              <DialogContentText>
                Do you want to apply to existing messages?
              </DialogContentText>

              <FormControl fullWidth>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  name='shouldApplyExisting'
                  value={shouldApplyExisting}
                  disabled={loading}
                  onChange={handleChange}
                >
                  <MenuItem value={0}>No</MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                </Select>
              </FormControl>
              <DialogContentText>
                <b>{nbMessagesConcerned}</b> existing messages are matching{' '}
                <Button
                  onClick={findMatchingMessages}
                  disabled={loading}
                  color='primary'
                >
                  RELOAD
                </Button>
              </DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={handleCreateFilters}
            disabled={loading}
            color='primary'
          >
            Release Pleco
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
