import React from 'react'

import { Grid, Paper, Button, TextField } from '@material-ui/core'

const style = {
  root: {
    flexGrow: 1,
  },
  email: {
    margin: '12px',
  },
  paper: {
    padding: '12px',
    margin: '12px',
    height: '80px',
    verticalAlign: 'center',
  },
}

export default function NewFilterForm({ email, handleChange, handleAddEmail }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper style={style.paper}>
          <Grid
            container
            spacing={2}
            style={{ top: '13%', position: 'relative' }}
            justify={'space-between'}
            alignItems='center'
          >
            <Grid item>
              <Grid container spacing={6}>
                <Grid item>
                  <img
                    alt='logo'
                    width={50}
                    src={process.env.PUBLIC_URL + `logo.png`}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    value={email}
                    name='customEmail'
                    placeholder='Enter a new email'
                    onChange={handleChange}
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Button variant='contained' color='secondary' onClick={handleAddEmail}>
                RAISE OWN PLECO
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
