import React from 'react'

import { Grid, Paper, ButtonBase, Typography, Button } from '@material-ui/core'

import CheckIcon from '@material-ui/icons/Check'

const style = {
  root: {
    flexGrow: 1,
  },
  email: {
    margin: '12px',
  },
  paper: {
    padding: '12px',
    margin: '12px',
    minHeight: '70px',
    verticalAlign: 'center',
  },
}

export default function Sender({ email, name, filterCreated }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper style={style.paper}>
          <Grid
            container
            spacing={2}
            style={{ top: '13%', position: 'relative' }}
            justify={'space-between'}
            alignItems='center'
          >
            <Grid item>
              <ButtonBase>
                <img
                  alt='logo'
                  width={150}
                  src={
                    process.env.PUBLIC_URL + `/images/${name.toLowerCase()}.png`
                  }
                />
              </ButtonBase>
              <Typography variant='caption' style={style.email}>
                {email}
              </Typography>
            </Grid>

            <Grid item>
              {filterCreated ? (
                <Typography variant={'caption'} style={{ color: '#008000' }}>
                  <CheckIcon style={{ color: '#008000' }} />
                  Your pleco has been released in your inbox
                </Typography>
              ) : (
                <Button variant='contained' color='primary'>
                  RAISE THIS PLECO
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
